<template>
  <v-row>
    <v-col>
      <v-btn
        v-if="canCreate('5')"
        class="primary btn-new-city"
        :to="{name:'FormWeeklyCanteenDish'}"
      >
        Добавить
      </v-btn>
      <v-select
        v-if="!user.city_id"
        v-model="city_id"
        :items="cities"
        item-value="id"
        item-text="name"
        @click="loadCities"
        :loading="isLoading"
        clearable
        label="Выберите город"
      />
      <v-select
        v-if="!user.catering_id"
        v-model="catering_id"
        :items="caterings"
        item-value="id"
        item-text="name"
        label="Выберите организацию"
        @click="loadCaterings"
        :loading="isLoading"
        clearable
      />
      <v-spacer />
      <h4 class="filter-name">Фильтр</h4>
      <v-row>
        <v-col
          md5
          xs12
        >
          <v-select
            v-model="canteenId"
            label="Столовая"
            :items="canteensOwn"
            item-text="name"
            item-value="id"
            @click="getCanteensByOwn"
          />
        </v-col>
        <v-col
          md5
          xs12
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                clearable
                label="Дата актуальности"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="dateOfRelevance = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateOfRelevance"
              @change="menuFrom = false"
              locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col col-xs-12>
          <v-btn
            class="primary"
            small
            col-xs-12
            @click="getAllWeeklyCanteenDishes()"
          >
            Применить
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <weekly-canteen-dishes-list
            :headers="headers"
            :items="weeklyCanteenDishes"
            @rowClick="selectWeeklyDish"
          />
        </v-col>
      </v-row>

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            @click:outside="isShowMore=false"
          >
            <v-card v-if="selectedWeeklyDish">
              <v-card-title>
                <span class="text-h5">{{selectedWeeklyDish.dish.name}}</span>
              </v-card-title>
              <v-card-text>
                <v-row
                  justify="center"
                  v-if="isLoading"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-container v-else>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Столовая: {{selectedWeeklyDish.canteen.name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Блюдо: {{selectedWeeklyDish.dish.name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-img :src="selectedWeeklyDish.dish.image" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Артикул: {{selectedWeeklyDish.dish.article}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Добавлено: {{selectedWeeklyDish.added_at}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Цена наличными: {{selectedWeeklyDish.dish.price_cash}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Цена безналичная: {{selectedWeeklyDish.dish.price_cashless}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Описание: {{selectedWeeklyDish.description  !== 'null' ? selectedWeeklyDish.description : ''}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Дополнительная информация: {{selectedWeeklyDish.other_info  !== 'null' ? selectedWeeklyDish.other_info : ''}} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
    <v-snackbar v-model="snackbar">
      {{snackbarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import WeeklyCanteenDishesList from "@/components/UI/List";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "Page",
  components: { WeeklyCanteenDishesList },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "День недели", value: "weekday.name" },
        { text: "Добавлено", value: "added_at" },
        { text: "Столовая", value: "canteen.name" },
        { text: "Название блюда", value: "dish.name" },
        { text: "Артикул", value: "dish.article" },
        { text: "Цена нал.", value: "dish.price_cash" },
        { text: "Цена безнал.", value: "dish.price_cashless" },
      ],
      weekdays: [
        { id: 1, name: "Понедельник" },
        { id: 2, name: "Вторник" },
        { id: 3, name: "Среда" },
        { id: 4, name: "Четверг" },
        { id: 5, name: "Пятница" },
        { id: 6, name: "Суббота" },
        { id: 7, name: "Воскресенье" },
      ],
      isShowMore: false,
      isLoading: false,
      selectedWeeklyDish: null,
      dateOfRelevance: "",
      menuFrom: false,
      canteensOwn: [],
      canteenId: null,
      weeklyCanteenDishes: [],
      caterings: [],
      catering_id: null,
      cities: [],
      city_id: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    computedDateFormattedMomentjs() {
      return this.dateOfRelevance
        ? format(parseISO(this.dateOfRelevance), "dd-MM-yyyy")
        : "";
    },
  },
  mounted() {
    this.getAllWeeklyCanteenDishes();
  },

  created() {
    this.getCanteensByOwn();
  },

  methods: {
    loadCities() {
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },
    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }

      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      }
      // console.log(queryString);
    },
    selectWeeklyDish(dish) {
      this.isShowMore = true;
      this.isLoading = true;
      let queryString = `/weekly-canteen-dishes/${dish.id}`;

      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }
      if (this.catering_id || this.user.catering_id) {
        this.axios(queryString)
          .then((resp) => {
            this.selectedWeeklyDish = resp.data.data.weeklyCanteenDish;
            this.isLoading = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.errors
              ? err.response.data.errors
              : err.response.data.data.message;
            this.isShowMore = false;
          });
      }
    },
    getCanteensByOwn() {
      let queryString = `/get-canteens-list-by-own-catering`;

      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }

      if (this.user.catering_id || this.catering_id) {
        this.axios
          .get(queryString)
          .then(({ data }) => {
            this.canteensOwn = [
              { id: null, name: "Нет" },
              ...data.data.canteens,
            ];
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data;
          });
      }
    },

    getAllWeeklyCanteenDishes() {
      let queryString = `/weekly-canteen-dishes?per_page=${this.perPage}&page=${this.page}`;

      if (this.canteenId) {
        queryString = `${queryString}&canteen_id=${this.canteenId}`;
      }

      if (this.dateOfRelevance) {
        const dateFromFmt = this.dateOfRelevance
          ? moment(this.dateOfRelevance).format("D-MM-YYYY")
          : null;
        queryString = `${queryString}&date_of_relevance=${dateFromFmt}`;
      }

      if (this.canteenId && this.dateOfRelevance) {
        queryString = `${queryString}&date_of_relevance=${this.dateOfRelevance}&canteen_id=${this.canteenId}`;
      }
      if (this.catering_id) {
        queryString += `&catering_id=${this.catering_id}`;
      }
      if (this.user.catering_id || this.catering_id) {
        this.axios(queryString).then(({ data }) => {
          console.log(data);
          this.weeklyCanteenDishes = data.data.weekly_canteen_dishes.data;
          this.weeklyCanteenDishes.map((dish) => {
            dish.weekday = this.weekdays.find((day) => day.id === dish.weekday);
          });
          console.log(data);
          this.totalPage = data.data.weekly_canteen_dishes.total;
        });
      }
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllWeeklyCanteenDishes();
      },
    },
    canteenId: {
      handler() {
        this.getAllWeeklyCanteenDishes();
      },
    },
    catering_id: {
      handler() {
        this.getAllWeeklyCanteenDishes();
        this.canteensOwn = [];
      },
    },
    weeklyCanteenDishes: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
.filter-name {
  margin-top: 20px;
}
</style>
