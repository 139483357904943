<template>
  <v-row>
    <v-col>
      <v-btn
        class="primary btn-new-city"
        to="/admin/canteens/create"
        v-if="canCreate('1')"
      >
        Добавить
      </v-btn>
      <v-spacer />
      <v-select
        v-model="defaultSelectedCity"
        :items="cities"
        item-text="name"
        item-value="id"
        label="Выберите город"
        return-object
      />

      <canteens-list
        v-if="defaultSelectedCity"
        :headers="headers"
        :items="canteens"
        @rowClick="selectCanteen"
      >
      </canteens-list>
      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            @click:outside="isShowMore=false"
          >
            <v-card v-if="selectedCanteen">
              <v-card-title>
                <span class="text-h5">{{selectedCanteen.name}}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>ID: {{selectedCanteen.id}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Имя директора: {{selectedCanteen.director_full_name}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p> Телефон: {{selectedCanteen.phonesStr}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p> Дополнительная информация:{{selectedCanteen.other_info}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Добавлено: {{selectedCanteen.added_at}}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import CanteensList from "@/components/UI/List";
import PageMixins from "@/mixins/page-mixin";

export default {
  name: "Page",
  components: { CanteensList },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Название", value: "name" },
        { text: "Организация", value: "catering.name" },
        { text: "Полное имя директора", value: "director_full_name" },
        // { text: "Телефон", value: "phones" },
        { text: "Добавлено", value: "added_at" },
      ],
      isShowMore: false,
      canteens: [],
      cities: [],
      defaultSelectedCity: null,
      selectedCanteen: null,
    };
  },

  mounted() {
    this.getAllCites();
  },

  methods: {
    selectCanteen(canteen) {
      if (canteen.phones.length > 0) {
        canteen.phonesStr = canteen.phones.join();
      }
      this.selectedCanteen = canteen;
      this.isShowMore = true;
    },
    getAllCanteens() {
      const vm = this;
      this.axios
        .get(
          `/canteens?city_id=${this.defaultSelectedCity.id}&per_page=${this.perPage}&page=${this.page}`
        )
        .then((resp) => {
          vm.canteens = resp.data.data.canteens.data; // resp.data.data.caterings.data
          vm.totalPage = resp.data.data.canteens.total;
        });
    },
    getAllCites() {
      const vm = this;
      this.axios
        .get(`/cities?per_page=${this.perPage}&page=${this.page}`)
        .then(function (resp) {
          vm.cities = resp.data.data.cities.data;
          window.sessionStorage.setItem("cities", JSON.stringify(vm.cities));
        });
    },
  },
  watch: {
    defaultSelectedCity: {
      handler() {
        this.getAllCanteens();
      },
    },
    page: {
      handler() {
        this.getAllCanteens();
      },
    },
    canteens: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
</style>