import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
// import VueAxios from "vue-axios";

import common from "./common";
import currentUser from "./currentUser";
import auth from "./auth";

Vue.use(Vuex);
// Vue.use(VueAxios, axios);
// Vue.axios.defaults.baseURL = "https://school-meals.a-lux.dev/api";
// Vue.axios.defaults.headers.common[
//   "Authorization"
// ] = `Bearer ${window.localStorage.getItem("token")}`;

export default new Vuex.Store({
  state: { isLoading: false, siteName: "" },
  mutations: {
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    // SET_SITE_NAME(state, payload) {
    //   state.siteName = payload;
    // },
  },
  actions: {
    updateIsLoding({ commit }, payload) {
      commit("SET_IS_LOADING", payload);
    },
    // saveSiteName({ commit }, payload) {
    //   Vue.axios.post("/site", { name: payload }).then((resp) => {
    //     commit("SET_SITE_NAME", resp);
    //   });
    // },
  },
  strict: false,
  modules: {
    currentUser,
    auth,
    common,
  },
});
