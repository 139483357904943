<template>
  <v-row
    cols="12"
    justify="center"
  >
    <v-col class="details">
      <v-btn
        class="primary"
        @click="closeDetails"
      >
        Назад
      </v-btn>
    </v-col>
    <v-card class="card">
      <v-img
        :src="items.complexDishes.image"
        height="200px"
      ></v-img>

      <v-card-title>
        <span>Имя: </span>
        <span>{{items.complexDishes.name}}</span>

      </v-card-title>

      <v-card-text>
        <span>Артикул: </span>
        <span> {{items.complexDishes.article}}</span>
      </v-card-text>

      <v-card-text>
        <span>Город: </span>
        <span> {{items.complexDishes.city.name}}</span>
      </v-card-text>
      <v-card-text>
        <span>Организация: </span>
        <span> {{items.complexDishes.catering.name}}</span>
      </v-card-text>

      <v-card-title>
        <span>Список: </span>
      </v-card-title>

      <v-list two-line>
        <v-list-item-group active-class="deep-purple--text">
          <template v-for="(item, index) in items.childDishes">
            <v-list-item :key="item.id">
              <template>
                <v-list-item-content @click="selectChildDish(item)">
                  <v-list-item-title v-text="item.name"></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="item.headline"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle v-text="item.article"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < items.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="isShowMore"
          persistent
          max-width="600px"
        >
          <v-card v-if="selectedDish">
            <v-card-title>
              <span class="text-h5">{{selectedDish.name}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>ID: {{selectedDish.id}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Имя: {{selectedDish.name}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-img :src="selectedDish.image" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Артикул: {{selectedDish.article}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Добавлено: {{selectedDish.added_at}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Цена наличными: {{selectedDish.price_cash}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Цена безналичная: {{selectedDish.price_cashless}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Описание: {{selectedDish.description  !== 'null' ? selectedDish.description : ''}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <p>Дополнительная информация: {{selectedDish.other_info  !== 'null' ? selectedDish.other_info : ''}} </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowMore = false"
              >
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      complexDishes: {
        type: Object,
      },
      childDishes: {
        type: Array,
      },
    },
  },
  data() {
    return {
      isShowMore: false,
      selectedDish: null,
    };
  },
  methods: {
    closeDetails() {
      this.$emit("closeDetails");
    },
    selectChildDish(dish) {
      this.isShowMore = true;
      this.selectedDish = dish;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin: 10px auto;
  width: 60%;
  // margin: 20px auto;
  @media screen and (max-width: 960px) {
    width: 95%;
  }
}
.btn-back {
  // position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999;
}
</style>