<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-text-field
      v-model.trim="id"
      label="Номер"
      required
      :rules='[(v) => !!v || "Поле \"Номер\"  обязательно"]'
      @input="inputId"
    ></v-text-field>
    <v-alert
      v-if="errorsMessages.id"
      dense
      outlined
      type="error"
    >
      {{ errorsMessages.id[0]}}
    </v-alert>

    <v-text-field
      v-model.trim="name"
      @input="inputName"
      label="Название"
      required
      :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
    ></v-text-field>
    <v-alert
      v-if="errorsMessages.name"
      dense
      outlined
      type="error"
    >
      {{ errorsMessages.name[0]}}
    </v-alert>
    <v-btn
      @click="save"
      class="ma-2"
      outlined
      color="indigo"
    >
      Сохранить
    </v-btn>
    <v-snackbar v-model="snackbar">
      {{snackbarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>

</template>

<script>
import FormMixin from "@/mixins/form-mixin.js";

export default {
  name: "Form",
  mixins: [FormMixin],
  data() {
    return {
      name: "",
      id: null,
      errorsMessages: {
        id: null,
        name: null,
      },
    };
  },
  methods: {
    inputId() {
      this.errorsMessages.id = null;
    },
    inputName() {
      this.errorsMessages.name = null;
    },
    save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        const vm = this;
        this.axios
          .post("/cities", { id: this.id, name: this.name })
          .then((resp) => {
            if (resp.status === 200) {
              vm.snackbar = true;
              vm.snackbarColor = "green";
              vm.snackbarText = resp.data.status;
              vm.newCity = resp.data.city;

              this.$nextTick(() => {
                vm.$emit("push", resp.data.data.city);
              });
              this.$refs.form.reset();
            }
          })
          .catch(({ response }) => {
            if (response.status === 422) {
              vm.snackbar = true;
              vm.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              vm.snackbar = true;
              vm.snackbarText = response.data.messages;
            }
          });
      }
    },
  },
};
</script>