// import transport from './transport';
import request from "./request";

export default {
  mixins: [request],
  data() {
    return {
      snackbar: false,
      snackbarText: "Ошибка",
      snackbarColor: "pink",
      required: [(v) => !!v || "Обязательно"],
      isNumber: [
        (v) => !!v || 'Поле "БИН" обязательно',
        (v) => /^[+-]?\d+(\.\d+)?$/.test(v) || 'Поле "БИН" должен быть числом',
      ],
      isFloat: [
        (v) => /^[+-]?\d+(\.\d+)?$/.test(v) || !v || "Должен быть числом",
      ],
      valid: false,
      isLen: [
        (v) =>
          v.length > 3 || "Название города должен быть больше трех символов",
      ],
    };
  },
};
