<template>

  <v-col>
    <h2>Создать complex category</h2>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model.trim="complexMenu.type_code"
        label="Код"
        required
        @input="inputTypeCode"
      ></v-text-field>
      <v-alert
        v-if="errorsMessages.type_code"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.type_code[0]}}
      </v-alert>

      <v-text-field
        v-model.trim="complexMenu.name"
        label="Название"
        required
        :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
        @input="inputName"
      ></v-text-field>
      <v-alert
        v-if="errorsMessages.name"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.name[0]}}
      </v-alert>
      <v-switch
        v-model="complexMenu.payable"
        :label="'Подлежит оплате'"
      ></v-switch>

      <v-btn
        @click="save"
        class="ma-2"
        outlined
        color="indigo"
      >
        Сохранить
      </v-btn>
      <v-snackbar v-model="snackbar">
        {{snackbarText}}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-col>

</template>

<script>
import FormMixin from "@/mixins/form-mixin.js";

export default {
  name: "Form",
  mixins: [FormMixin],
  data() {
    return {
      complexMenu: {
        type_code: null,
        name: "",
        payable: false,
      },
      errorsMessages: {
        type_code: null,
        name: null,
      },
    };
  },
  methods: {
    inputTypeCode() {
      this.errorsMessages.type_code = null;
    },
    inputName() {
      this.errorsMessages.name = null;
    },

    save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        const vm = this;
        this.axios
          .post("/dic-complex-categories", this.complexMenu)
          .then((resp) => {
            vm.snackbar = true;
            vm.snackbarColor = "green";
            vm.snackbarText = resp.data.status;
            const newComplex = resp.data.data.dic_complex_category;
            newComplex.payableText = newComplex.payable ? "Да" : "Нет";
            this.$nextTick(() => {
              vm.$emit("push", newComplex);
            });

            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            vm.snackbarColor = "pink";
            if (response.status === 422) {
              vm.snackbar = true;
              vm.snackbarColor = "pink";
              vm.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              vm.snackbar = true;
              vm.snackbarText = response.data.messages;
            }
          });
      }
    },
  },
};
</script>