<template>
  <v-row class="row-reverse">
    <v-col>
      <v-btn
        v-if="canCreate('1')"
        class="primary btn-new-city"
        @click="createDocType"
      >
        Добавить
      </v-btn>
      <v-spacer />

      <canteen-document-types-list
        :headers="headers"
        :items="dicDocumentTypes"
      />

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <!-- <v-row justify="center">
        <v-dialog
          v-model="isShowMore"
          persistent
          max-width="600px"
          @click:outside="isShowMore=false"
        >
          <v-card v-if="selectedDocumentType">
            <v-card-title>
              <span class="text-h5">{{selectedDocumentType.name}}</span>
            </v-card-title>
            <v-card-text>
              <v-row
                justify="center"
                v-if="isLoading"
              >
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </v-row>
              <v-container v-else>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <p>Код: {{selectedDocumentType.code}} </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <p>Имя: {{selectedDocumentType.name}}</p>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="isShowMore = false"
              >
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row> -->
    </v-col>
    <v-col>
      <form-canteen-doc-type
        v-if="isCreate"
        @push="puhsDocType"
      />
    </v-col>
  </v-row>
</template>
 

<script>
import CanteenDocumentTypesList from "@/components/UI/List";
import FormCanteenDocType from "./Form.vue";
import PageMixins from "@/mixins/page-mixin";

export default {
  name: "Page",
  components: { CanteenDocumentTypesList, FormCanteenDocType },
  mixins: [PageMixins],
  data() {
    return {
      headers: [
        { text: "Код", value: "code" },
        { text: "Название", value: "name" },
      ],
      isShowMore: false,
      isCreate: false,
      dicDocumentTypes: [],
      selectedDocumentType: null,
      isLoading: false,
    };
  },

  mounted() {
    this.getAllCanteenDocumentTypes();
  },

  methods: {
    createDocType() {
      this.isCreate = true;
    },
    puhsDocType(docType) {
      this.dicDocumentTypes.push(docType);
      this.isCreate = false;
    },
    selectDocType({ code }) {
      this.isShowMore = true;
      this.isLoading = true;

      const vm = this;
      this.axios(`/canteen-document-types/${code}`).then((resp) => {
        vm.selectedDocumentType = resp.data.data.dic_document_type;
        vm.isLoading = false;
      });
    },

    getAllCanteenDocumentTypes() {
      const vm = this;
      this.axios
        .get(
          `/canteen-document-types?per_page=${this.perPage}&page=${this.page}`
        )
        .then(function (resp) {
          vm.dicDocumentTypes = resp.data.data.dic_document_types.data;
          vm.totalPage = resp.data.data.dic_document_types.total;
        });
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllCanteenDocumentTypes();
      },
    },
    dicDocumentTypes: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style>
</style>