<template>
  <v-row>
    <v-col>
      <h2>Добавить организацию</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-model="newCatering.city_id"
          :items="cities"
          item-text="name"
          item-value="id"
          label="Выберите город*"
          :rules='[(v) => !!v || "Поле \"Город\"  обязательно"]'
          clearable
        />
        <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newCatering.name"
          label="Название*"
          :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
          required
        />
        <v-alert
          v-if="errorsMessages.name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.name[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newCatering.director_full_name"
          label="Полное имя директора*"
          required
          :rules='[(v) => !!v || "Поле \"Полное имя директора\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.director_full_name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.director_full_name[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="newCatering.bin"
          label="БИН*"
          :rules="isNumber"
        />
        <v-alert
          v-if="errorsMessages.bin"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.bin[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="newCatering.bik"
          label="BIK"
        />
        <v-alert
          v-if="errorsMessages.bik"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.bik[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newCatering.iban"
          label="IBAN"
        />
        <v-alert
          v-if="errorsMessages.iban"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.iban[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newCatering.bank_name"
          label="Название банка"
        />
        <v-alert
          v-if="errorsMessages.bank_name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.bank_name[0]}}
        </v-alert>
        <v-text-field
          v-model="phones"
          label="Телефон"
          type="tel"
        />
        <v-textarea
          v-model.trim="newCatering.other_info"
          label="Дополнительная информация"
        ></v-textarea>

        <v-snackbar v-model="snackbar">
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>

</template>

<script>
import FormMixin from "@/mixins/form-mixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isAdded: false,
      phones: null,
      newCatering: {
        name: "",
        city_id: null,
        director_full_name: "",
        bin: "",
        iban: null,
        bik: null,
        bank_name: null,
        phones: [],
        other_info: null,
      },
      errorsMessages: {
        name: "",
        city_id: null,
        director_full_name: "",
        bin: "",
        iban: null,
        bik: null,
        bank_name: null,
      },
      cities: [],
      defaultSelectedCity: null,
    };
  },
  computed: {
    clonedCatering() {
      return JSON.parse(JSON.stringify(this.newCatering));
    },
  },

  created() {
    this.cities = JSON.parse(window.sessionStorage.getItem("cities"));
  },
  methods: {
    save() {
      this.valid = this.$refs.form.validate();
      this.newCatering.phones =
        this.phones && this.phones != "" ? [...this.phones.split(",")] : [];
      if (this.valid) {
        this.axios
          .post("/caterings", this.newCatering)
          .then((resp) => {
            if (resp.status === 200) {
              this.snackbar = true;
              this.snackbarColor = "green";
              this.snackbarText = "Успешно добавлено";
              this.$refs.form.reset();
              // this.$router.push({ name: "Catergins" });
            }
          })
          .catch(({ response }) => {
            console.log(response);
            if (response.status === 422) {
              this.snackbar = true;
              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              this.snackbarText = response.data.messages;
            }
          });
      }
    },
  },

  watch: {
    cities: {
      handler() {
        this.defaultSelectedCity = this.cities.length > 0 ? this.cities[0] : {};
      },
    },
    clonedCatering: {
      deep: true,
      handler(newCatering, oldCatering) {
        if (newCatering.name !== oldCatering.name) {
          this.errorsMessages.name = null;
        }
        if (newCatering.city_id !== oldCatering.city_id) {
          this.errorsMessages.city_id = null;
        }
        if (newCatering.director_full_name !== oldCatering.director_full_name) {
          this.errorsMessages.director_full_name = null;
        }
        if (newCatering.bin !== oldCatering.bin) {
          this.errorsMessages.bin = null;
        }
        if (newCatering.iban !== oldCatering.iban) {
          this.errorsMessages.iban = null;
        }
        if (newCatering.bik !== oldCatering.bik) {
          this.errorsMessages.last_name = null;
        }
        if (newCatering.bank_name !== oldCatering.bank_name) {
          this.errorsMessages.last_name = null;
        }
      },
    },
  },
};
</script>