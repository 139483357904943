<template>
  <v-row>
    <v-col>
      <v-data-table
        class="list"
        :headers="headers"
        hide-default-footer
        :items="items"
         @click:row="handleRowClick"
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>Список </v-toolbar-title>
            <v-divider class="mx-4" inset vertical />
            <v-spacer />
          </v-toolbar>
        </template>
        <slot name="downloadLink"></slot>
      </v-data-table>
    </v-col>
  </v-row>

</template>

<script>

export default {
  name: "List",
  props:{
      headers:{
          type:Array,
          required:true,
      },
      items:{
          type:Array,
          // required:true
      }
  },
  methods:{
    handleRowClick(row){
      this.$emit('rowClick', row);
    }
  }
}
</script>

<style scoped>


</style>