<template>
  <div class="page-not-found">
    <h1>Страница не найдено</h1>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      if (this.$store.state.auth.token) {
        this.$router.push("/admin/index");
      } else {
        this.$router.push("/admin/login");
      }
    }, 3000);
  },
};
</script>


<style>
.page-not-found {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
}
h1 {
  height: 200px;
}
</style>