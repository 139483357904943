<template>
  <v-row>
    <v-col>
      <h2>Создать доумент</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-if="!user.city_id"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="isLoading"
          clearable
          label="Выберите город"
        />
        <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert>
        <v-select
          v-if="!user.catering_id"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="isLoading"
          clearable
        />
        <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert>
        <v-select
          v-if="!user.canteen_id"
          v-model="canteen_id"
          :items="canteens"
          item-value="id"
          item-text="name"
          @click="loadCanteens"
          :loading="isLoading"
          clearable
          label="Столовая"
        />
        <v-alert
          v-if="errorsMessages.canteen_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.canteen_id[0]}}
        </v-alert>
        <v-select
          v-model="newDocument.type_code"
          :items="docTypes"
          item-text="name"
          item-value="code"
          label="Выберите тип документа"
          @click="loadDocTypes"
          clearable
          :rules='[(v) => !!v || "Поле \"Тип документа\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.type_code"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.type_code[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newDocument.number"
          label="Номер документа"
          required
          :rules='[(v) => !!v || "Поле \"Номер документа\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.number"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.number[0]}}
        </v-alert>
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="newDocument.date"
              clearable
              label="Дата"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="dateFrom = null"
              :rules='[(v) => !!v || "Поле \"Дата\"  обязательно"]'
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDocument.date"
            @change="menuDate = false"
            locale="ru-RU"
          />
        </v-menu>
        <v-alert
          v-if="errorsMessages.date"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.date[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="newDocument.name"
          label="Название"
          required
        />
        <v-alert
          v-if="errorsMessages.name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.name[0]}}
        </v-alert>
        <div
          class="file-dragable"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <v-file-input
            truncate-le
            ngth="15"
            label="Выберите файл или перетащите"
            v-model="uploadFile"
            ref="file"
            :rules='[(v) => !!v || "Поле \"Файл\"  обязательно"]'
          ></v-file-input>
        </div>
        <v-alert
          v-if="errorsMessages.file"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.file[0]}}
        </v-alert>
        <v-menu
          v-model="menuValidity"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="newDocument.validity"
              clearable
              label="Срок действия"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="newDocument.validity = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDocument.validity"
            @change="menuValidity = false"
            locale="ru-RU"
          />
        </v-menu>
        <v-alert
          v-if="errorsMessages.validity"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.validity[0]}}
        </v-alert>
        <v-snackbar v-model="snackbar">
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>
</template>

<script>
import FormMixin from "@/mixins/form-mixin";
import { mapState } from "vuex";
export default {
  mixins: [FormMixin],
  data() {
    return {
      menuDate: false,
      menuValidity: false,
      docDate: "",
      docTypes: [],
      newDocument: {
        name: "",
        type_code: null,
        date: "",
        number: "",
        validity: null,
      },
      errorsMessages: {
        name: null,
        type_code: null,
        date: null,
        number: null,
        validity: null,
        file: null,
        city_id: null,
        catering_id: null,
        canteen_id: null,
      },
      cities: [],
      city_id: null,
      caterings: [],
      catering_id: null,
      isLoading: false,
      canteens: [],
      canteen_id: null,
      uploadFile: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    clonedNewDocumetn() {
      return JSON.parse(JSON.stringify(this.newDocument));
    },
  },
  mounted() {
    this.getAllCanteenDocumentTypes();
  },

  methods: {
    loadCities() {
      this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.errorsMessages.catering_id = null;
      //console.log(this.city_id);
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
      console.log(queryString);
    },
    loadCanteens() {
      this.isLoading = true;
      let queryString = "/get-canteens-list-by-own-catering";
      this.errorsMessages.canteen_id = null;
      //console.log(this.city_id);
      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }

      if (this.catering_id || this.user.catering_id) {
        this.errorsMessages.catering_id = null;
        this.axios(queryString).then((response) => {
          this.canteens = response.data.data.canteens;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.canteen_id = ["Поле canteen_id"];
      }
    },
    loadDocTypes() {
      this.errorsMessages.type_code = null;
      if (this.docTypes.length) {
        this.getAllCanteenDocumentTypes();
      }
    },
    onChange() {
      // console.log(this.$refs.file.files);
      this.uploadFile = this.$refs.file.files[0];
      // console.log("change");
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {},
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    getAllCanteenDocumentTypes() {
      const vm = this;
      this.axios.get(`/canteen-document-types`).then(function (resp) {
        vm.docTypes = resp.data.data.dic_document_types;
      });
    },
    getFormData() {
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      if (this.canteen_id) {
        formData.append("canteen_id", this.canteen_id);
      }

      for (const [key, value] of Object.entries(this.newDocument)) {
        if (this.newDocument[key] && this.newDocument[key]) {
          formData.append(key, value);
        }
      }
      if (!this.newDocument.name) {
        formData.append("name", this.uploadFile.name);
      }
      return formData;
    },
    save() {
      this.valid = this.$refs.form.validate();
      const formData = this.getFormData();

      if (this.valid) {
        this.axios
          .post("/canteen-documents", formData)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Успешно добавлено";
            // alert();
            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            console.log(response);
            if (response.status === 422) {
              this.snackbar = true;
              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              // console.log(response.data.data);
              this.snackbarText = response.data.data.message;
            }
          });
      }
    },
  },
  watch: {
    uploadFile: {
      handler() {
        this.errorsMessages.file = null;
        if (!this.newDocument.name && this.uploadFile) {
          this.newDocument.name = this.uploadFile.name;
        }
      },
    },
    clonedNewDocumetn: {
      deep: true,
      handler(newDocument, oldDocument) {
        if (newDocument.name !== oldDocument.name) {
          this.errorsMessages.name = null;
        }
        // if (newDocument.type_code !== oldDocument.type_code) {
        //   this.errorsMessages.type_code = null;
        // }
        if (newDocument.date !== oldDocument.date) {
          this.errorsMessages.date = null;
        }
        if (newDocument.number !== oldDocument.number) {
          this.errorsMessages.number = null;
        }
        if (newDocument.validity !== oldDocument.validity) {
          this.errorsMessages.validity = null;
        }
      },
    },
  },
};
</script>