<template>
  <v-row>
    <v-col>
      <template v-if="!isShowMore">
        <v-btn
          v-if="canCreate('3')"
          class="primary btn-new-city"
          :to="{name:'FormComplexDishes'}"
        >
          Добавить
        </v-btn>
        <v-spacer />
        <v-select
          v-if="!user.city_id"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="isLoading"
          clearable
          label="Выберите город"
        />
        <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert>
        <v-select
          v-if="!user.catering_id"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="isLoading"
          clearable
        />
        <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert>
        <v-row>
          <v-col>
            <complex-dishes-list
              :headers="headers"
              :items="complexDishes"
              @rowClick="selectComplexDish"
            />
          </v-col>
        </v-row>

        <template v-if="paginationLen">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="paginationLen"
            ></v-pagination>
          </div>
        </template>

      </template>

      <template v-else>
        <v-row
          cols="12"
          justify="center"
          v-if="isLoading"
        >

          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          />

        </v-row>

        <complex-dish-details
          v-else
          :items="selectedComplexDish"
          @closeDetails="isShowMore = false"
          :cateringId="catering_id"
          :cityId="city_id"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import ComplexDishesList from "@/components/UI/List";
import ComplexDishDetails from "./Details";
import { mapState } from "vuex";

export default {
  name: "Page",
  components: { ComplexDishesList, ComplexDishDetails },
  mixins: [PageMixins],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Название", value: "name" },
        { text: "Артикул", value: "article" },
        { text: "Цена наличными", value: "price_cash" },
        { text: "Цена безналичная", value: "price_cashless" },
        { text: "Добавлено", value: "added_at" },
      ],
      isShowMore: false,
      isLoading: false,
      selectedComplexDish: {
        complexDishes: null,
        childDishes: null,
      },
      complexDishes: [],
      cities: [],
      city_id: null,
      caterings: [],
      catering_id: null,
      errorsMessages: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    if (this.user.catering_id || this.catering_id) {
      this.getAllComplexDishes();
    }
  },

  methods: {
    loadCities() {
      this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.dishes = [];
      this.errorsMessages.catering_id = null;
      //console.log(this.city_id);
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
      console.log(queryString);
    },
    selectComplexDish(dish) {
      this.isShowMore = true;
      this.isLoading = true;
      let queryString = `/complex-dishes/${dish.id}`;
      // const cityId = this.user.city_id ? this.user.city_id : this.city_id;

      // if (this.city_id) {
      //   queryString += `?city_id=${this.city_id}`;
      // }
      //When we have city_id
      if (this.catering_id && !this.city_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }
      if (this.city_id && this.catering_id) {
        queryString += `?city_id=${this.city_id}&catering_id=${this.catering_id}`;
      }
      // if (this.catering_id && cityId) {
      //   queryString += `?catering_id=${this.catering_id}`;
      // }

      this.axios(queryString).then((resp) => {
        this.selectedComplexDish.complexDishes = resp.data.data.complex_dish;
        this.selectedComplexDish.childDishes = resp.data.data.child_dishes;
        this.isLoading = false;
      });
    },

    getAllComplexDishes() {
      let queryString = `/complex-dishes?per_page=${this.perPage}&page=${this.page}`;
      if (this.catering_id) {
        queryString += `&catering_id=${this.catering_id}`;
      }
      if (this.city_id) {
        queryString += `&city_id=${this.city_id}`;
      }
      this.axios(queryString).then(({ data }) => {
        console.log(data);
        this.complexDishes = data.data.dishes.data;
        this.totalPage = data.data.dishes.total;
      });
    },
  },
  watch: {
    catering_id: {
      handler(id) {
        if (id) {
          this.getAllComplexDishes();
        }
      },
    },
    page: {
      handler() {
        this.getAllWeeklyCanteenDishes();
      },
    },

    complexDishes: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>