<template>
  <v-row>

    <v-col>
      <v-btn
        v-if="canCreate('8')"
        class="primary btn-new-city"
        to="/admin/canteen-document/create"
      >
        Добавить
      </v-btn>
      <v-spacer />
      <v-select
        v-if="!user.city_id"
        v-model="city_id"
        :items="cities"
        item-value="id"
        item-text="name"
        @click="loadCities"
        :loading="isLoading"
        clearable
        label="Выберите город"
      />
      <v-alert
        v-if="errorsMessages.city_id"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.city_id[0]}}
      </v-alert>
      <v-select
        v-if="!user.catering_id"
        v-model="catering_id"
        :items="caterings"
        item-value="id"
        item-text="name"
        label="Выберите организацию"
        @click="loadCaterings"
        :loading="isLoading"
        clearable
      />
      <v-alert
        v-if="errorsMessages.catering_id"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.catering_id[0]}}
      </v-alert>
      <v-select
        v-if="!user.canteen_id"
        v-model="canteen_id"
        :items="canteens"
        item-value="id"
        item-text="name"
        @click="loadCanteens"
        :loading="isLoading"
        clearable
        label="Столовая"
      />
      <h4 class="filter-name">Фильтры по дате</h4>
      <v-row>
        <v-col
          cols="12"
          lg="4"
        >
          <v-menu
            v-model="menuFrom"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                clearable
                label="С"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="dateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              @change="menuFrom = false"
              locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          lg="4"
        >
          <v-menu
            v-model="menuTo"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedDatefns"
                clearable
                label="До"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="dateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              @change="menuTo = false"
              locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-btn
            class="primary"
            small
            cols="12"
            lg="4"
            @click="getDocumentsByDate()"
          >
            Применить
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-if="canteenDocuments"
            class="list"
            :headers="headers"
            hide-default-footer
            :items="canteenDocuments"
            @click:row="selectCanteenDoc"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Список </v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-spacer />
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <a href="{{item.download_link}}"></a> -->
              <v-icon
                small
                class="mr-2"
                @click="downloadDocument(item)"
              >
                mdi-file-download
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            v-click-outside="isShowMore=false"
          >
            <v-card v-if="selectedCanteenDoc">
              <v-card-title>
                <span class="text-h5">{{ selectedCanteenDoc.name }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <p>ID: {{ selectedCanteenDoc.id }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <p>Номер: {{ selectedCanteenDoc.number }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Организация: {{ selectedCanteenDoc.canteen.name }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Код типа: {{ selectedCanteenDoc.doc_type.code }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>
                        Название типа: {{ selectedCanteenDoc.doc_type.name }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Дата утверждения: {{ selectedCanteenDoc.date }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>
                        Срок окончания:
                        {{
                          selectedCanteenDoc.validity
                            ? selectedCanteenDoc.validity
                            : "Отсутствует"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>
                        <a :href="selectedCanteenDoc.download_link">Скачать</a>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
// import CanteenDocumentsList from '@/components/UI/List';
import PageMixins from "@/mixins/page-mixin";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { mapState, mapActions } from "vuex";

export default {
  name: "Page",
  // components:{ CanteenDocumentsList },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Имя", value: "name" },
        { text: "Номер", value: "number" },
        { text: "Код", value: "doc_type.code" },
        { text: "Тип", value: "doc_type.name" },
        { text: "Название организации", value: "canteen.name" },
        { text: "Добавлено", value: "added_at" },
        { text: "Действия", value: "actions" },
      ],
      isShowMore: false,
      canteenDocuments: null,
      selectedCanteenDoc: null,
      dateFrom: "",
      dateTo: "",
      menuFrom: false,
      menuTo: false,
      cities: [],
      city_id: null,
      caterings: [],
      catering_id: null,
      errorsMessages: {},
      isLoading: false,
      canteens: [],
      canteen_id: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      isProgress: (state) => state.isLoading,
    }),
    computedDateFormattedMomentjs() {
      return this.dateFrom ? format(parseISO(this.dateFrom), "dd-MM-yyyy") : "";
    },
    computedDateFormattedDatefns() {
      return this.dateTo ? format(parseISO(this.dateTo), "dd-MM-yyyy") : "";
    },
  },

  mounted() {
    if (this.user.catering_id || this.catering_id) {
      this.getAllCanteenDocuments();
    }
  },

  methods: {
    ...mapActions(["updateIsLoading"]),
    loadCities() {
      this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.caterings = [];
      this.canteens = [];
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.errorsMessages.catering_id = null;
      this.canteens = [];
      //console.log(this.city_id);
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
      console.log(queryString);
    },
    loadCanteens() {
      this.isLoading = true;
      let queryString = "/get-canteens-list-by-own-catering";
      this.errorsMessages.canteen_id = null;
      //console.log(this.city_id);
      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }

      if (this.catering_id || this.user.catering_id) {
        this.errorsMessages.catering_id = null;
        this.axios(queryString).then((response) => {
          this.canteens = response.data.data.canteens;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.canteen_id = ["Поле canteen_id"];
      }
    },
    downloadDocument(docInfo) {
      window.open(docInfo.download_link);
    },
    getDocumentsByDate() {
      const dateFromFmt = this.dateFrom
        ? moment(this.dateFrom).format("DD-MM-YYYY")
        : null;
      const dateToFmt = this.dateTo
        ? moment(this.dateTo).format("DD-MM-YYYY")
        : null;

      if (dateFromFmt && dateToFmt) {
        this.getAllCanteenDocuments(dateFromFmt, dateToFmt);
      }
      if (dateFromFmt && !dateToFmt) {
        this.getAllCanteenDocuments(dateFromFmt);
      }
      if (!dateFromFmt && !dateToFmt) {
        this.getAllCanteenDocuments();
      }
    },
    getAllCanteenDocuments(dateFrom = null, dateTo = null) {
      let queryString = `/canteen-documents-by-period?per_page=${this.perPage}&page=${this.page}`;
      if (this.canteen_id) {
        queryString += `&canteen_id=${this.canteen_id}`;
      }
      this.$store.dispatch("updateIsLoding", true);
      // this.updateIsLoading(true);

      const vm = this;
      if (dateFrom && dateTo) {
        queryString += `&date_from=${dateFrom}&date_to=${dateTo}`;
        this.axios.get(queryString).then((resp) => {
          vm.canteenDocuments = resp.data.data.canteen_documents.data;
          vm.totalPage = resp.data.data.canteen_documents.total;
          vm.$store.dispatch("updateIsLoding", false);
        });
        return;
      }
      if (dateFrom && !dateTo) {
        queryString += `&date=${dateFrom}`;
        this.axios.get(queryString).then((resp) => {
          vm.canteenDocuments = resp.data.data.canteen_documents.data;
          vm.totalPage = resp.data.data.canteen_documents.total;
          vm.$store.dispatch("updateIsLoding", false);
        });
      }
      if (!dateFrom && !dateTo) {
        queryString += `&page=${this.page}`;
        this.axios.get(queryString).then((resp) => {
          vm.canteenDocuments = resp.data.data.canteen_documents.data;
          vm.totalPage = resp.data.data.canteen_documents.total;
          vm.$store.dispatch("updateIsLoding", false);
        });
      }
    },
    selectCanteenDoc(canteenDoc) {
      this.selectedCanteenDoc = canteenDoc;
      this.isShowMore = true;
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllCanteenDocuments();
      },
    },
    canteen_id: {
      handler(id) {
        console.log(id);
        if (id) {
          this.getAllCanteenDocuments();
        }
      },
    },
    canteenDocuments: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
.filter-name {
  margin-top: 20px;
}
</style>
