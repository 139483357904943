<template>

  <v-col>
    <h2>Создать тип документа</h2>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model.trim="documentType.type_code"
        label="Код"
        required
        :rules='[(v) => !!v || "Поле \"Код\"  обязательно"]'
        @input="inputTypeCode"
      ></v-text-field>
      <v-alert
        v-if="errorsMessages.type_code"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.type_code[0]}}
      </v-alert>
      <v-text-field
        v-model.trim="documentType.name"
        label="Название"
        required
        @input="inputName"
        :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
      ></v-text-field>
      <v-alert
        v-if="errorsMessages.name"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.name[0]}}
      </v-alert>

      <v-btn
        @click="save"
        class="ma-2"
        outlined
        color="indigo"
      >
        Сохранить
      </v-btn>
      <v-snackbar v-model="snackbar">
        {{snackbarText}}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-col>

</template>

<script>
import FormMixin from "@/mixins/form-mixin.js";

export default {
  name: "Form",
  mixins: [FormMixin],
  data() {
    return {
      documentType: {
        type_code: null,
        name: "",
      },
      errorsMessages: {
        type_code: null,
        name: null,
      },
    };
  },
  methods: {
    inputTypeCode() {
      this.errorsMessages.type_code = null;
    },
    inputName() {
      this.errorsMessages.name = null;
    },
    save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        const vm = this;
        this.axios
          .post("/canteen-document-types", this.documentType)
          .then((resp) => {
            if (resp.status === 200) {
              vm.snackbar = true;
              vm.snackbarColor = "green";
              vm.snackbarText = resp.data.status;

              this.$nextTick(() => {
                vm.$emit("push", resp.data.data.dic_document_type);
              });

              this.$refs.form.reset();
            }
          })
          .catch(({ response }) => {
            if (response.status === 422) {
              vm.snackbar = true;
              vm.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              vm.snackbar = true;
              vm.snackbarText = response.data.messages;
            }
          });
      }
    },
  },
};
</script>