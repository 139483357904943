const state = () => ({
  token: null,
  refreshToken: null,
});

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  CLEAR_TOKEN(state) {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    state.token = null;
    state.refreshToken = null;
  },
};

const getters = {};

const actions = {
  saveToken: ({ commit }, token) => {
    commit("SET_TOKEN", token);
  },
  saveRefreshToken({ commit }, refreshToken) {
    commit("SET_REFRESH_TOKEN", refreshToken);
  },
};

export default {
  //   namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
