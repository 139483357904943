<template>
  <v-row>
    <v-col>
      <h2>Создать недельное меню</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-if="!user.city_id"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="loading"
          clearable
          label="Выберите город"
        />
        <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert>
        <v-select
          v-if="!user.catering_id"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="loading"
          clearable
        />
        <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert>
        <v-select
          v-model="weeklyDish.canteen_id"
          label="Столовая"
          :items="canteensOwn"
          item-text="name"
          item-value="id"
          clearable
          @click="getCanteensByOwn"
          :rules='[(v) => !!v || "Поле \"Столовая\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.canteen_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.canteen_id[0]}}
        </v-alert>
        <v-select
          v-model="weeklyDish.weekday"
          label="Выберите день недели"
          :items="weekdays"
          item-text="name"
          item-value="id"
          clearable
          @click="clearErrorMessage"
        />
        <v-alert
          v-if="errorsMessages.weekday"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.weekday[0]}}
        </v-alert>
        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="dishes"
          :search-input.sync="search"
          item-text="fullName"
          item-value="id"
          label="Введите артикул"
          return-object
          clearable
          :rules='[(v) => !!v || "Поле \"Артикул\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.dish_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.dish_id[0]}}
        </v-alert>

        <v-snackbar v-model="snackbar">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import FormMixin from "@/mixins/form-mixin";
import { mapState } from "vuex";

export default {
  mixins: [FormMixin],
  data() {
    return {
      canteensOwn: [],
      weekdays: [
        { id: 1, name: "Понедельник" },
        { id: 2, name: "Вторник" },
        { id: 3, name: "Среда" },
        { id: 4, name: "Четверг" },
        { id: 5, name: "Пятница" },
        { id: 6, name: "Суббота" },
        { id: 7, name: "Воскресенье" },
      ],
      weeklyDish: {
        dish_id: null,
        canteen_id: null,
        weekday: null,
      },
      errorsMessages: {
        dish_id: null,
        canteen_id: null,
        weekday: null,
        city_id: null,
        catering_id: null,
      },
      dishes: [],
      loading: false,
      search: null,
      select: {
        id: null,
        fullName: null,
        name: null,
        article: null,
      },
      caterings: [],
      catering_id: null,
      cities: [],
      city_id: null,
      // isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  created() {
    this.getCanteensByOwn();
  },

  methods: {
    clearErrorMessage() {
      this.errorsMessages.weekday = null;
    },
    loadCities() {
      this.errorsMessages.city_id = null;
      this.loading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.loading = false;
      });
    },
    loadCaterings() {
      this.errorsMessages.catering_id = null;
      this.loading = true;
      let queryString = "/caterings";
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }

      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.loading = false;
        });
      }
      // console.log(queryString);
    },
    getCanteensByOwn() {
      let queryString = `/get-canteens-list-by-own-catering`;

      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }

      if (this.user.catering_id || this.catering_id) {
        this.axios
          .get(queryString)
          .then(({ data }) => {
            this.canteensOwn = [
              { id: null, name: "Нет" },
              ...data.data.canteens,
            ];
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data;
          });
      }
    },
    // getCanteensByOwn() {
    //   console.log("getowncanteen", this.errorsMessages);
    //   this.errorsMessages.canteen_id = null;
    //   this.axios
    //     .get(`/get-canteens-list-by-own-catering`)
    //     .then(({ data }) => {
    //       this.canteensOwn = data.data.canteens;
    //     })
    //     .catch((err) => {
    //       this.snackbar = true;
    //       this.snackbarText = err.response.data.errors
    //         ? err.response.data.errors
    //         : err.response.data.data.message;
    //       this.loading = false;
    //     });
    // },
    save() {
      this.valid = this.$refs.form.validate();
      this.weeklyDish.dish_id = this.select.id;
      //   const formData = this.getFormData();

      if (this.valid) {
        if (this.catering_id) {
          this.weeklyDish.catering_id = this.catering_id;
        }
        this.axios
          .post("/weekly-canteen-dishes", this.weeklyDish)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Успешно добавлено";
            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            console.log(response);
            if (response.status === 422) {
              this.snackbar = true;
              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              // console.log(response.data.data);
              this.snackbarText = response.data.data.message;
            }
          });
      }
    },
    getDishesByArticle(query) {
      this.errorsMessages.dish_id = null;
      this.loading = true;
      let queryString = `/get-own-catering-dishes-by-article?article=${query}`;
      if (this.catering_id) {
        queryString += `&catering_id=${this.catering_id}`;
      }
      if (this.city_id) {
        queryString += `&city_id=${this.city_id}`;
      }
      this.axios
        .get(queryString)
        .then((resp) => {
          this.loading = false;
          this.dishes = resp.data.data.dishes.map((item) => {
            item.fullName = `${item.article} ${item.name}`;
            return item;
          });
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.snackbar = true;
            this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
            this.errorsMessages = response.data.errors;
          } else {
            this.snackbar = true;
            this.snackbarText = response.data.data.message;
          }
        });
    },
  },
  watch: {
    search(val) {
      if (val && this.select.fullName !== val) {
        this.getDishesByArticle(val);
      }
    },
    catering_id: {
      handler() {
        this.canteensOwn = [];
      },
    },
  },
};
</script>
