<template>
  <v-row class="row-reverse">
    <v-col>
      <v-btn
        v-if="canCreate('1')"
        class="primary btn-new-city"
        @click="addNewCity"
      >
        Добавить
      </v-btn>
      <v-spacer />
      <cities-list
        :headers="headers"
        :items="cities"
      />
      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
    </v-col>

    <v-col>
      <form-city
        v-if="isCreate"
        @push="pushCity"
      />
    </v-col>

  </v-row>

</template>

<script>
import CitiesList from "@/components/UI/List";
import PageMixins from "@/mixins/page-mixin";
import FormCity from "./Form.vue";

export default {
  name: "Page",
  components: { CitiesList, FormCity },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      isCreate: false,
      headers: [
        { text: "Номер города", value: "id" },
        { text: "Название", value: "name" },
      ],
      cities: [],
    };
  },
  mounted() {
    this.getAllCites();
  },

  methods: {
    pushCity(city) {
      this.cities.push(city);
      this.isCreate = false;
    },
    addNewCity() {
      this.isCreate = true;
    },
    getAllCites() {
      var vm = this;
      this.axios
        .get(`/cities?per_page=${this.perPage}&page=${this.page}`)
        .then(function (resp) {
          console.log(resp);
          vm.cities = resp.data.data.cities.data;
          vm.totalPage = resp.data.data.cities.total;
        })
        .catch((err) => {
          console.log("FROM CITES CATCH", err);
        });
    },
  },

  watch: {
    cities: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
    page: {
      handler() {
        this.getAllCites();
      },
    },
  },
};
</script>