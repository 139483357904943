<template>
  <v-card
    elevation="1"
    class="mx-auto"
    width="400px"
  >
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="blue darken-1 accent-4"
    ></v-progress-linear>
    <v-card-title>Вход</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
        <v-text-field
          v-model.trim="login"
          :rules="rules.login"
          label="Логин"
          ref="login"
        />

        <v-text-field
          v-model.trim="password"
          :rules="rules.password"
          type="password"
          label="Пароль"
          ref="password"
        />
        <v-card-actions>
          <v-btn
            class="ml-auto"
            text
            color="primary"
            type="submit"
            :disabled="loading"
          >
            Войти
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>

    <v-snackbar v-model="snackbar">
      {{snackbarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>

</template>

<script>
import FormMixin from "@/mixins/form-mixin.js";
// import { mapActions } from "vuex";

export default {
  name: "Login",
  mixins: [FormMixin],
  data() {
    return {
      valid: true,
      login: null,
      password: null,
      rules: {
        login: [(v) => !!v || "Введите логин"],
        password: [(v) => !!v || "Введите пароль"],
      },
      loading: false,
    };
  },

  created() {
    // // this.$router.push("/admin/index");
    // if (this.$store.state.token && this.$router.path !== "/admin/index") {
    //   this.$router.push("/admin/index");
    // }
  },
  mounted() {
    const vLabelLogin = this.$refs.login.$el.querySelector(".v-label");
    const vLabelPassword = this.$refs.password.$el.querySelector(".v-label");
    vLabelLogin.classList.add("v-label--active");
    vLabelPassword.classList.add("v-label--active");
  },
  methods: {
    submit() {
      this.loading = true;
      this.valid = this.$refs.form.validate();
      console.log("Submiting....");

      if (!this.valid) return;

      const vm = this;
      const formData = new FormData();

      formData.append("login", this.login);
      formData.append("password", this.password);

      this.axios
        .post("/login", { login: this.login, password: this.password })
        .then((resp) => {
          if (resp.data.status === "failure") {
            vm.snackbarText = resp.data.data.message;
            return;
          }

          window.localStorage.setItem(
            "user",
            JSON.stringify(resp.data.data.user)
          );
          window.localStorage.setItem("token", resp.data.data.access_token);
          window.localStorage.setItem(
            "refresh_token",
            resp.data.data.refresh_token
          );
          this.loading = false;
          this.$router.push("/admin/index");

          vm.snackbar = true;
          vm.snackbarColor = "green";
          vm.snackbarText = resp.data.status;
        })
        .catch((err) => {
          this.loading = false;
          vm.snackbar = true;
          vm.snackbarText = err.response.data.errors.login[0];
        });
    },
  },
};
</script>
