<template>
  <div></div>
</template>
<script>
import PageMixins from "@/mixins/page-mixin";

export default {
  name: "Logout",
  mixins: [PageMixins],
  mounted() {
    this.logout();
    // setTimeout(() => {
    //   this.$router.push("/admin/login");
    // }, 3000);
  },
  methods: {
    logout() {
      this.axios.post("/logout").then((resp) => {
        // console.log(resp);
        if (resp.status === 200) {
          this.$store.commit("CLEAR_TOKEN");
          this.$store.commit("CLEAR_USER");
          this.$router.push("/admin/login");
        }
      });
    },
  },
};
</script>
