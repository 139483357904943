import Vue from "vue";
import request from "@/mixins/request";
Vue.use(request);
export default {
  state: {
    cities: [],
  },
  mutations: {
    SAVE_CITIES(state, cities) {
      state.cities = cities;
    },
  },
  actions: {
    loadCities({ commit }) {
      this.axios.get(`/cities`).then((resp) => {
        commit("SAVE_CITIES", resp.data.data.cities);
      });
    },
  },
};
