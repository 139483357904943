<template>
  <v-row>
    <v-col>
      <h2>Создать комплексное меню</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-if="!user.city_id"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="isLoading"
          clearable
          label="Выберите город"
        />
        <!-- <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert> -->
        <v-select
          v-if="!user.catering_id"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="isLoading"
          :rules='[(v) => !!v || "Поле \"Организация\"  обязательно"]'
          clearable
        />
        <!-- <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert> -->
        <v-select
          v-model="complexDish.complex_dish_id"
          label="Комплескное меню"
          :items="complexDishes"
          item-text="name"
          item-value="id"
          :rules='[(v) => !!v || "Поле \"Комплескное меню\"  обязательно"]'
          clearable
          @click="loadComplexDishes"
        />
        <!-- <v-alert
          v-if="errorsMessages.complex_dish_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.complex_dish_id[0]}}
        </v-alert> -->
        <v-select
          v-model="complexDish.child_dish_ids"
          label="Блюдо"
          :items="complexChildDishes"
          item-text="name"
          item-value="id"
          clearable
          :rules='[(v) => v.length>0 || "Поле \"Блюдо\"  обязательно"]'
          multiple
          @click="loadComplexChildDishes"
        />
        <v-alert
          v-if="errorsMessages"
          dense
          outlined
          type="error"
        >
          <span
            v-for="(msg, idx) in errorsMessages"
            :key="idx"
          >

            {{msg[0]}}
            <br />
            <!-- {{errorsMessages}} -->
          </span>

          <!-- {{ errorsMessages}} -->
        </v-alert>

        <v-snackbar v-model="snackbar">
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>
</template>

<script>
import FormMixin from "@/mixins/form-mixin";
import { mapState } from "vuex";
export default {
  mixins: [FormMixin],
  data() {
    return {
      complexDish: {
        complex_dish_id: null,

        child_dish_ids: [],
      },
      errorsMessages: null,
      complexDishes: [],
      complexChildDishes: [],
      cities: [],
      city_id: null,
      caterings: [],
      catering_id: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  created() {
    if (this.user.catering_id || this.catering_id) {
      this.getAllComplexDishes();
      this.getAllComplexChildDishes();
    }
  },
  methods: {
    loadCities() {
      // this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.dishes = [];
      // this.errorsMessages.catering_id = null;
      //console.log(this.city_id);
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
      console.log(queryString);
    },
    loadComplexDishes() {
      // this.errorsMessages.complex_dish_id = null;
      this.getAllComplexDishes();
    },
    loadComplexChildDishes() {
      // this.errorsMessages.child_dish_id = null;
      this.getAllComplexChildDishes();
    },
    save() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        if (this.catering_id) {
          this.complexDish.catering_id = this.catering_id;
        }
        if (this.city_id) {
          this.complexDish.city_id = this.city_id;
        }
        this.axios
          .post("/complex-dishes", this.complexDish)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Успешно добавлено";
            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            this.snackbarColor = "pink";
            if (response.status === 422) {
              this.snackbar = true;
              if (response.data.errors.city_id) {
                this.snackbarText = response.data.errors.city_id;
                return;
              }

              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              this.snackbarText = response.data.data.message;
            }
          });
      }
    },

    getAllComplexDishes() {
      let queryString = `/complex-dishes`;

      if (this.catering_id && !this.city_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }
      if (this.city_id && this.catering_id) {
        queryString += `?city_id=${this.city_id}&catering_id=${this.catering_id}`;
      }

      this.axios(queryString).then(
        ({ data }) => (this.complexDishes = data.data.dishes)
      );
    },
    getAllComplexChildDishes() {
      let queryString = `/get-child-dishes`;

      if (this.catering_id && !this.city_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }
      if (this.city_id && this.catering_id) {
        queryString += `?city_id=${this.city_id}&catering_id=${this.catering_id}`;
      }
      this.axios(queryString)
        .then(({ data }) => (this.complexChildDishes = data.data.child_dishes))
        .catch(({ response }) => {
          if (response.status === 422) {
            this.snackbar = true;
            if (response.data.errors.city_id) {
              this.snackbarText = response.data.errors.city_id;
              return;
            }

            this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
            this.errorsMessages = response.data.errors;
          } else {
            this.snackbar = true;
            this.snackbarText = response.data.data.message;
          }
        });
    },
  },

  watch: {},
};
</script>