import request from "./request";

export default {
  mixins: [request],
  data() {
    return {
      perPage: 5,
      page: 1,
      currentPage: 0,
      paginationLen: 0,
      totalPage: 0,
      snackbar: false,
      snackbarText: "Ошибка",
      snackbarColor: "pink",
    };
  },
  methods: {
    canCreate(permissionName) {
      let canStore = false;
      const currentUserPermissions = this.$store.state.currentUser.user
        .permissions;
      for (let i = 0; i < currentUserPermissions.length; i++) {
        if (currentUserPermissions[i].name === permissionName) {
          canStore = true;
          break;
        }
      }

      return canStore;
    },
  },
};
