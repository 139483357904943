<template>
  <v-row>
    <v-col>
      <h2>Добавить столовую</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-model="defaultSelectedCity"
          :items="cities"
          item-text="name"
          item-value="id"
          label="Выберите город"
          :rules='[(v) => !!v || "Поле \" Город \"  обязательно"]'
          clearable
          @click="loadCities"
          :loading="isLoading"
        />

        <v-select
          v-model="newCanteen.catering_id"
          :items="caterings"
          item-text="name"
          item-value="id"
          label="Выберите организацию*"
          clearable
          @click="loadCaterings"
          :rules='[(v) => !!v || "Поле \"Организация\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert>

        <v-text-field
          v-model.trim="newCanteen.name"
          label="Название*"
          :rules='[(v) => !!v || "Поле \" Название\"  обязательно"]'
          required
        />
        <v-alert
          v-if="errorsMessages.name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.name[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="newCanteen.director_full_name"
          label="Полное имя директора*"
          required
          :rules='[(v) => !!v || "Поле \"Полное имя директора\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.director_full_name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.director_full_name[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="phones"
          label="Телефон (77024907855, 77074692568, 77070001122)"
          type="tel"
        />
        <v-textarea
          v-model.trim="newCanteen.other_info"
          label="Дополнительная информация"
        ></v-textarea>
        <v-snackbar v-model="snackbar">
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>

</template>

<script>
import FormMixin from "@/mixins/form-mixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isAdded: false,
      isLoading: false,
      phones: null,
      newCanteen: {
        name: "",
        catering_id: null,
        director_full_name: "",
        other_info: null,
        phones: [],
      },
      errorsMessages: {
        name: "",
        catering_id: null,
        director_full_name: "",
      },
      caterings: [],
      cities: [],
      defaultSelectedCatering: null,
      defaultSelectedCity: null,
    };
  },
  computed: {
    clonedCanteen() {
      return JSON.parse(JSON.stringify(this.newCanteen));
    },
  },
  mounted() {
    this.getAllCites();
  },

  methods: {
    loadCities() {
      this.getAllCites();
    },
    loadCaterings() {
      this.getAllCaterings();
      this.errorsMessages.catering_id = null;
    },
    getAllCites() {
      const vm = this;
      // this.isLoading = true;
      this.axios.get(`/cities`).then(function (resp) {
        vm.cities = resp.data.data.cities;
        window.sessionStorage.setItem("cities", JSON.stringify(vm.cities));
        vm.isLoading = false;
      });
    },

    getAllCaterings() {
      if (this.defaultSelectedCity) {
        const vm = this;
        this.axios
          .get(`/caterings?city_id=${this.defaultSelectedCity}`)
          .then((resp) => {
            vm.caterings = resp.data.data.caterings;
          });
      }
    },

    save() {
      this.valid = this.$refs.form.validate();
      this.newCanteen.phones =
        this.phones && this.phones != "" ? [...this.phones.split(",")] : [];
      if (this.valid) {
        this.axios
          .post("/canteens", this.newCanteen)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Успешно добавлено";
            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            console.log(response);
            if (response.status === 422) {
              this.snackbar = true;
              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              this.snackbarText = response.data.data.message;
            }
          });
      }
    },
  },

  watch: {
    defaultSelectedCity: {
      handler() {
        this.getAllCaterings();
      },
    },
    clonedCatering: {
      deep: true,
      handler(newCanteen, oldCanteen) {
        if (newCanteen.name !== oldCanteen.name) {
          this.errorsMessages.name = null;
        }
        if (newCanteen.catering_id !== oldCanteen.catering_id) {
          this.errorsMessages.catering_id = null;
        }
        if (newCanteen.director_full_name !== oldCanteen.director_full_name) {
          this.errorsMessages.director_full_name = null;
        }
      },
    },
  },
};
</script>