<template>
  <v-row>
    <v-col>
      <v-btn
        v-if="canCreate('10')"
        class="primary btn-new-city"
        to="/admin/dishes/create"
      >
        Добавить
      </v-btn>
      <v-spacer />
      <v-select
        v-if="!user.city_id"
        v-model="city_id"
        :items="cities"
        item-value="id"
        item-text="name"
        @click="loadCities"
        :loading="isLoading"
        clearable
        label="Выберите город"
      />

      <dishes-list
        v-if="dishes"
        :headers="headers"
        :items="dishes"
        @rowClick="selectDish"
      />

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            @click:outside="isShowMore=false"
          >
            <v-card v-if="selectedDish">
              <v-card-title>
                <span class="text-h5">{{selectedDish.name}}</span>
              </v-card-title>
              <v-card-text>
                <v-row
                  justify="center"
                  v-if="isLoading"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-container v-else>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>ID: {{selectedDish.id}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Блюдо: {{selectedDish.name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-img :src="selectedDish.image" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Артикул: {{selectedDish.article}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Добавлено: {{selectedDish.added_at}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Цена наличными: {{selectedDish.price_cash}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Цена безналичная: {{selectedDish.price_cashless}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Описание: {{selectedDish.description  !== 'null' ? selectedDish.description : ''}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Дополнительная информация: {{selectedDish.other_info  !== 'null' ? selectedDish.other_info : ''}} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import DishesList from "@/components/UI/List";
import PageMixins from "@/mixins/page-mixin";
import { mapState } from "vuex";

export default {
  name: "Page",
  components: { DishesList },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Имя", value: "name" },
        { text: "Артикул", value: "article" },
        { text: "Цена наличными", value: "price_cash" },
        { text: "Цена безналичная", value: "price_cashless" },
      ],
      isShowMore: false,
      dishes: [],
      selectedDish: null,
      isLoading: false,
      cities: [],
      city_id: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    this.getAllDishes();
  },

  methods: {
    loadCities() {
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },
    selectDish(dish) {
      this.isShowMore = true;
      this.isLoading = true;
      this.axios(`/dishes/${dish.id}`).then((resp) => {
        this.selectedDish = resp.data.data.dish;
        this.isLoading = false;
      });
    },
    getAllDishes() {
      let cityId = this.city_id ? this.city_id : this.user.city_id;
      let queryString = `/dishes?per_page=${this.perPage}&page=${this.page}`;
      if (cityId) {
        queryString += `&city_id=${cityId}`;
      }

      this.axios(queryString).then((resp) => {
        this.dishes = resp.data.data.dishes.data;
        this.totalPage = resp.data.data.dishes.total;
      });
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllDishes();
      },
    },
    city_id: {
      handler() {
        this.getAllDishes();
      },
    },
    dishes: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>