<template>
  <div class="index-page">
    <h1>ИС БОШП</h1>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Index",
  created() {
    if (localStorage.key("token")) {
      this.saveUser(JSON.parse(localStorage.getItem("user")));
      this.saveToken(localStorage.getItem("token"));
      this.saveRefreshToken(localStorage.getItem("refresh_token"));
    }
  },
  methods: {
    ...mapActions(["saveToken", "saveUser", "saveRefreshToken"]),
  },
};
</script>
<style scoped>
.index-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
</style>