const state = () => ({
  user: null,
});

const mutations = {
  UPDATE_CURRENT_USER_PERMISSIONS: (state, permissions) => {
    state.user.permissions = permissions;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER(state) {
    localStorage.removeItem("user");
    state.user = null;
  },
};

const getters = {};

const actions = {
  saveUser: ({ commit }, user) => {
    commit("SET_USER", user);
  },
  updateUserPermissions: ({ commit }, permissions) => {
    commit("UPDATE_CURRENT_USER_PERMISSIONS", permissions);
  },
};

export default {
  //   namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
