<template>
  <v-row>
    <v-col>
      <v-btn
        v-if="canCreate('1')"
        class="primary btn-new-city"
        to="/admin/users/create"
      >
        Добавить
      </v-btn>
      <v-spacer />

      <v-data-table
        v-if="users"
        class="list"
        :headers="headers"
        hide-default-footer
        :items="users"
      >

        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>Список </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-spacer />
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="showPermisionsDilog(item)"
          >
            mdi-shield-account
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="selectUser(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            @click:outside="isShowMore=false"
          >
            <v-card v-if="selectedUser">
              <v-card-title>
                <span class="text-h5">{{selectedUser.firstname}}</span>
              </v-card-title>
              <v-card-text>
                <v-row
                  justify="center"
                  v-if="isLoading"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-container v-else>

                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Имя: {{selectedUser.first_name}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Фамилия: {{selectedUser.last_name}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Логин: {{selectedUser.login}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Email: {{selectedUser.email}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Город: {{selectedUser.city ? selectedUser.city.name: "Нет"}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Организация: {{selectedUser.catering ? selectedUser.catering.name : "Нет"}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Столовая: {{selectedUser.canteen ? selectedUser.canteen.name : "Нет"}}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Создана: {{new Date(selectedUser.created_at).toLocaleString('ru-RU')}}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="isEditPermission"
            persistent
            max-width="600px"
            @click:outside="isEditPermission=false"
          >
            <v-card v-if="selectedUser">
              <v-card-title>
                <span class="text-h5">{{selectedUser.login}}</span>
              </v-card-title>
              <v-card-text
                align="center"
                justify="center"
              >
                <v-progress-circular
                  v-if="isLoading"
                  :size="70"
                  :width="7"
                  color="blue"
                  indeterminate
                />

                <template v-else>
                  <v-checkbox
                    v-for="role in permissions"
                    v-model="selectedUser.permissions"
                    :key="role.id"
                    :label="role.title"
                    :value="role"
                    color="success"
                    :value-comparator="comparatorPermission"
                    hide-details
                  ></v-checkbox>
                </template>

              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="savePermissions"
                >
                  Сохранить
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isEditPermission = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
        </v-row>
      </template>
    </v-col>
    <v-snackbar v-model="snackbar">
      {{snackbarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbarColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import { mapActions } from "vuex";

export default {
  name: "Page",
  mixins: [PageMixins],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Имя", value: "first_name" },
        { text: "Фамилия", value: "last_name" },
        { text: "Login", value: "login" },
        { text: "Телефон", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Действия", value: "actions" },
      ],
      isShowMore: false,
      users: [],
      selectedUser: null,
      isLoading: false,
      isEditPermission: false,
      permissions: [],
    };
  },

  created() {
    this.getAllPermissions();
  },

  mounted() {
    this.getAllUsers();
  },

  methods: {
    ...mapActions(["updateUserPermissions"]),
    comparatorPermission(a, b) {
      return a?.name === b?.name;
    },

    getAllPermissions() {
      this.isLoading = true;
      this.axios("/get-permissions").then((resp) => {
        this.permissions = resp.data.data.permissions;
        this.isLoading = false;
      });
    },
    savePermissions() {
      const permissions = this.selectedUser.permissions.map((item) => {
        return item.name;
      });
      this.axios
        .put(`/users/${this.selectedUser.id}/update-permissions`, {
          permissions,
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = resp.data.status;
            this.isEditPermission = false;

            if (this.selectedUser.id == this.$store.state.currentUser.user.id) {
              this.updateUserPermissions(this.selectedUser.permissions);
            }
          }
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message;
        });
    },
    showPermisionsDilog(user) {
      this.isEditPermission = true;
      this.isLoading = true;
      this.selectedUser = user;
      this.axios(`/users/${user.id}`).then((resp) => {
        this.selectedUser = resp.data.data.user;
        this.isLoading = false;
      });
    },
    selectUser(user) {
      this.isShowMore = true;
      this.isLoading = true;
      // const vm = this;
      this.axios(`/users/${user.id}`).then((resp) => {
        console.log(resp.data.data.user);
        // const user = resp.data.data.user;
        // console.log(user.canteen);
        // if (user.canteen === undefined) {
        //   alert();
        //   user.canteen = { name: "Нет" };
        // }
        // if (user.city === undefined) {
        //   user.city = { name: "Нет" };
        // }
        // if (user.catering === undefined) {
        //   user.catering = { name: "Нет" };
        // }
        // console.log(user);
        this.selectedUser = resp.data.data.user;
        this.isLoading = false;
      });
    },
    getAllUsers() {
      this.axios(`/users?per_page=${this.perPage}&page=${this.page}`).then(
        (resp) => {
          this.users = resp.data.data.users.data;
          this.totalPage = resp.data.data.users.total;
        }
      );
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllUsers();
      },
    },
    users: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
</style>