import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/components/Index";
import CitesPage from "@/components/cities/Page";
import Login from "@/components/auth/Form";
import Logout from "@/components/auth/Logout";
import CateringsPage from "@/components/caterings/Page";
import FormCaterging from "@/components/caterings/Form";
import CanteensPage from "@/components/canteens/Page";
import FormCanteens from "@/components/canteens/Form";
import CanteenDocTypePage from "@/components/document-types/Page";
import CanteenDocumentPage from "@/components/canteen-documents/Page";
import FormCanteenDocument from "@/components/canteen-documents/Form";
import UsersPage from "@/components/users/Page";
import FormUser from "@/components/users/Form";
import ComplexCatergoriesPage from "@/components/complex-catergories/Page";
import DishesPage from "@/components/dishes/Page";
import FormDish from "@/components/dishes/Form";
import DishesOwnPage from "@/components/dishes-own/Page";
import FormDishOwn from "@/components/dishes-own/Form";
import WeeklyCanteenDishes from "@/components/weekly-canteen-dishes/Page";
import FormWeeklyCanteenDish from "@/components/weekly-canteen-dishes/Form";
import WeeklySchoolDishes from "@/components/weekly-school-dishes/Page";
import ComplexDishes from "@/components/complex-dishes/Page";
import FormComplexDish from "@/components/complex-dishes/Form";
import StatisticsPage from "@/components/statistics/Page";
import PageNotFound from "@/components/page-not-found/PageNotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin/users",
    name: "Users",
    component: UsersPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/users/create",
    name: "FormUser",
    component: FormUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/cities",
    name: "Cities",
    component: CitesPage,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin/caterings",
    name: "Catergins",
    component: CateringsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/caterings/create",
    name: "FormCaterging",
    component: FormCaterging,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/canteens",
    name: "CanteensPage",
    component: CanteensPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/canteens/create",
    name: "FormCanteens",
    component: FormCanteens,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/canteen-document-types",
    name: "ConteenDocType",
    component: CanteenDocTypePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/canteen-documents",
    name: "ConteenDocuments",
    component: CanteenDocumentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/canteen-document/create",
    name: "FormCanteenDocument",
    component: FormCanteenDocument,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/complex-categories",
    name: "ComplexCategories",
    component: ComplexCatergoriesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/dishes",
    name: "Dishes",
    component: DishesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/dishes/create",
    name: "FormDish",
    component: FormDish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/dishes-own",
    name: "DishesOwnPage",
    component: DishesOwnPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/dishes-own/create",
    name: "FormDishOwn",
    component: FormDishOwn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/weekly-canteen-dishes",
    name: "WeeklyCanteenDishes",
    component: WeeklyCanteenDishes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/weekly-canteen-dishes/create",
    name: "FormWeeklyCanteenDish",
    component: FormWeeklyCanteenDish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/weekly-school-dishes",
    name: "WeeklySchoolDishes",
    component: WeeklySchoolDishes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/complex-dishes",
    name: "ComplexDishes",
    component: ComplexDishes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/complex-dishes/create",
    name: "FormComplexDishes",
    component: FormComplexDish,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/statistics",
    name: "StatisticsPage",
    component: StatisticsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/admin/index",
    name: "Index",
    component: Index,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    redirect: "/admin/index",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    redirect: "/admin/index",
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.document.title = localStorage.getItem("app_name");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //   toRoute.meta && to.meta.title ? to.meta.title : "Home";
    if (!window.localStorage.getItem("token")) {
      next({ path: "/admin/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
