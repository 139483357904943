<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <v-select
        v-if="!user.statistic_id"
        v-model="statistic_id"
        :items="statistics"
        item-value="id"
        item-text="name"
        label="Выберите отчет"
        @click="loadStatistic"
        :loading="isLoading"
        clearable
      />
      <v-alert
        v-if="errorsMessages.statistic_id"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.statistic_id[0]}}
      </v-alert>
      <v-select
        v-if="!user.statistic_group_id"
        v-model="statistic_group_id"
        :items="statistic_groups"
        item-value="id"
        item-text="name"
        label="Выберите группировку"
        @click="loadStatisticGroups"
        :loading="isLoading"
        clearable
      />
      <v-alert
        v-if="errorsMessages.statistic_group_id"
        dense
        outlined
        type="error"
      >
        {{ errorsMessages.statistic_group_id[0]}}
      </v-alert>
      <v-row>
        <v-col>
          <v-btn
            class="primary"
            small
            cols="12"
            lg="4"
            @click="viewStatistic()"
          >
            Cформировать
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <div id="viewer"></div>
    </v-col>
  </v-row>

</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import { mapState, mapActions } from "vuex";
import "@/../public/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";

export default {
  name: "Page",
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      statistic_id: undefined,
      statistics: [],
      statistic_group_id: undefined,
      statistic_groups: [],
      errorsMessages: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      isProgress: (state) => state.isLoading,
    }),
  },

  mounted() {
      /**
       * Подключаем скрипты и стили Stimulsoft
       */
      let reportScript = document.createElement('script');
      reportScript.setAttribute('src', '/stimulsoft/stimulsoft.reports.js');
      document.head.appendChild(reportScript);
      
      let viewerScript = document.createElement('script');
      viewerScript.setAttribute('src', '/stimulsoft/stimulsoft.viewer.js');
      document.head.appendChild(viewerScript);

  },

  methods: {
    ...mapActions(["updateIsLoading"]),

    viewStatistic() {

      if(!this.statistic_id || !this.statistic_group_id) {
        return;
      }

      this.axios('/statistics/'+this.statistic_id+'/file?grouping='+this.statistic_group_id).then(({data: file}) => {
        console.log(file.Pages[0].Components[2].Condition.Value);
        var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
        viewer.renderHtml('viewer');
        
        var report = new window.Stimulsoft.Report.StiReport();
        report.load(file);
        viewer.report = report;
      });
    },

    loadStatistic() {
      this.errorsMessages.statistic_id = null;

      this.isLoading = true;

      this.statistics = [{id: 'canteens', name: 'Отчет по столовым'}, {id: 'menu', name: 'Отчет по меню'}];

      this.isLoading = false;
    },

    loadStatisticGroups() {
      this.isLoading = true;

      if (this.statistic_id || this.user.statistic_id) {

        if(this.statistic_id == 'canteens') {

          this.statistic_groups = [{id: 'city', name: 'Группировка по городу'}, {id: 'cartering', name: 'Группировка по компании'}];

        } else if(this.statistic_id == 'menu') {

          this.statistic_groups = [{id: 'city', name: 'Группировка по городу'}, {id: 'cartering', name: 'Группировка по компании'}];

        }
      } else {
        this.errorsMessages.statistic_id = ["Выберите отчет"];
      }

      this.isLoading = false;
    },
  },
  watch: {
    page: {
      handler() {

      },
    },
  },
};
</script>

<style scoped>
.filter-name {
  margin-top: 20px;
}
</style>
