<template>
  <v-row class="row-reverse">
    <v-col>
      <v-btn
        class="primary btn-new-city"
        @click="createComplexCategory"
      >
        Добавить
      </v-btn>
      <v-spacer />

      <complex-categories-list
        :headers="headers"
        :items="dicComplexCategories"
      />

      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>

    </v-col>
    <v-col>
      <form-complex-category
        v-if="isCreate"
        @push="puhsComplexCategory"
      />
    </v-col>
  </v-row>
</template>
 

<script>
import ComplexCategoriesList from "@/components/UI/List";
import FormComplexCategory from "./Form.vue";
import PageMixins from "@/mixins/page-mixin";

export default {
  name: "Page",
  components: { ComplexCategoriesList, FormComplexCategory },
  mixins: [PageMixins],
  data() {
    return {
      headers: [
        { text: "Код", value: "code" },
        { text: "Называние", value: "name" },
        { text: "Payable", value: "payableText" },
      ],
      isShowMore: false,
      isCreate: false,
      dicComplexCategories: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getAllCanteenDocumentTypes();
  },

  methods: {
    createComplexCategory() {
      this.isCreate = true;
    },
    puhsComplexCategory(complexCat) {
      this.dicComplexCategories.push(complexCat);
      this.isCreate = false;
    },
    getAllCanteenDocumentTypes() {
      const vm = this;
      this.axios
        .get(
          `/dic-complex-categories?per_page=${this.perPage}&page=${this.page}`
        )
        .then(function (resp) {
          vm.dicComplexCategories =
            resp.data.data.dic_complex_categories.data.map((item) => {
              if (item.payable) item.payableText = "Да";
              else item.payableText = "Нет";
              return item;
            });
          vm.totalPage = resp.data.data.dic_complex_categories.total;
        });
    },
  },
  watch: {
    page: {
      handler() {
        this.getAllCanteenDocumentTypes();
      },
    },
    dicComplexCategories: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
</style>